<template>
  <div>
    <b-modal title="상품상세" size="xl" v-model="modal.detail">
      <template v-slot:modal-title>
        <b-badge variant="success">{{item.shop_id}}. {{item.shop}}</b-badge>
        <b-badge class="ml-1" variant="warning">{{item.brand_nm}} ({{item.brand_nm_kr}})</b-badge>
        <b-badge class="ml-1" variant="light">{{item.cate}}</b-badge>
        <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.category)"><i class="fa fa-copy"></i></b-badge>
        <b-badge class="ml-1 pointer" variant="info" @click="copyText(item.goods_no)">{{item.goods_no}} <i class="fa fa-copy ml-1"></i></b-badge>
        <br/>
        <span v-if="item.goodsType === 'used'" class="text-danger">[빈티지{{item.usedGrade || '등급없음'}}]</span> {{item.goods_nm}}
        <i v-if="editMode" class="ml-1 fa fa-pencil pointer" @click="setField('goods_nm')"></i><br/>
      </template>

      <b-row>
        <b-col cols="12" md="8">
          <b-row class="mb-2">
            <b-col cols="5">
              <small>가격</small><br/>
              <b-row>
                <b-col>
                  <div class="text-right pr-3">
                    <div>{{utils.comma(item.consumer)}} 원</div>
                    <div>{{utils.comma(item.standardPrice)}} 원</div>
                  </div>
                </b-col>
                <b-col>
                  <div class="text-right pr-3">
                    <div v-if="item.epPrice !== item.price"><del>{{utils.comma(item.price)}}</del> 원</div>
                    <div>{{utils.comma(item.epPrice)}} 원</div>
                    <div v-if="item.epPrice!== item.price">({{100-Math.round(item.epPrice/item.price*100)}} %)</div>
                  </div>
                </b-col>
              </b-row>

              <a :href="`${MALL_URL}/shop/goods/goods_view.php?goodsno=${item.goods_no}`" target="_blank"
                 class="btn btn-sm btn-outline-dark">발란몰에서 상품보기 <i class="fa fa-external-link"></i></a><br/>

<!--              <small>발란몰 : </small>
              <a :href="`${this.MALL_URL}/shop/goods/goods_view.php?goodsno=${item.goods_no}`" target="_blank"><b-badge variant="info">PC</b-badge></a>
              <a :href="`${this.MALL_URL}/m2/goods/view.php?goodsno=${item.goods_no}`" class="ml-1 badge alert-info" target="_blank">Mobile</a><br/>-->
              <!--<small>쇼핑몰 상품코드 : </small>
              <a v-if="MALL_GOODS_LINK[item.mall]" :href="`${MALL_GOODS_LINK[item.mall]}${item.mall_goodsno}`" target="_blank"><b-badge variant="info">{{item.mall_goodsno}}</b-badge></a>
              <b-badge v-else variant="light">{{item.mall_goodsno}}</b-badge><br/>-->
<!--              <small>SKU : </small>
              <template v-if="item.sku_id">
                <a :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${(item.sku_id||'').replace(' ', '+')}`" target="_blank">
                  <b-badge variant="success">{{item.sku_id}} <i class="fa fa-external-link"></i></b-badge>
                </a>
                <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.sku_id)"><i class="fa fa-copy"></i></b-badge>
              </template>
              <i v-if="editMode" class="ml-1 fa fa-pencil pointer" @click="setField('sku_id')"></i><br/>
              <small>파트너 상품코드 : </small>
              <a v-if="item.crawl_url && item.crawl_url.startsWith('http')" :href="`https://hub.balaan.io/r?u=${encodeURIComponent(item.crawl_url)}`" target="_blank">
                <b-badge variant="info">{{ item.goods_id || 'link' }} <i class="fa fa-external-link"></i></b-badge>
              </a>
              <b-badge v-else variant="light">{{ item.goods_id }}</b-badge>
              <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.goods_id)"><i class="fa fa-copy"></i></b-badge>
              &nbsp;<i class="fa fa-question-circle" v-b-tooltip="'파트너 상품링크가 있다면 파란색으로 표시되며 클릭하면 새 창으로 띄워집니다. OKMALL은 차단되어 있으니 링크를 복사하여 vpn이나 proxy를 이용 바랍니다.'"></i><br/>-->
            </b-col>
            <b-col cols="7">
              <div v-if="item.options && item.options.length">
                <b-badge variant="light">{{item.options[0].optnm}}</b-badge>
                <span class="badge badge-success">판매가능</span>
                <span class="badge badge-primary">SHOP재고</span>
                <span class="badge badge-info">결제수량</span>
                <span class="badge badge-warning">10분 미결제수량</span>
                <br/>
                <template v-for="e in item.options" v-if="!e.not_found">
                  <b-badge variant="light">{{e.Size}}</b-badge>
                  <span class="badge badge-success" title="판매 가능 재고수">{{e.stock}}</span>
                  <span class="badge badge-primary" title="SHOP 원본 재고수">{{e.shop_stock}}</span>
                  <span v-if="e.order_stock" class="badge badge-info" title="결제된 주문수량">{{e.order_stock}}</span>
                  <span v-if="e.prepared_stock" class="badge badge-warning" title="10분이내 주문 미결제수량">{{e.prepared_stock}}</span>&nbsp;
                  &nbsp;
                  <span v-if="e.goods_price !== item.price"><small>{{utils.comma(e.goods_price)}} 원 ({{utils.delta(e.goods_price - item.price, 2)}} 원)</small></span>
                  <span v-else class="text-muted"><small>(가격동일)</small></span>
                  <b-badge v-if="e.soldout" class="ml-1" variant="danger" title="품절이력이 있어서 재고가 되살아나지 않는 옵션입니다">품절</b-badge>
                  <br/>
                </template>
              </div>
            </b-col>
          </b-row>

          <div v-if="item.naver" class="mb-3">
            <small>Naver 최저가</small><br/>
            {{item.naver.rank}} 위 ({{item.naver._dt}} 기준,
            <span v-if="item.naver.other_product_cnt">다른 판매자의 상품 {{item.naver.other_product_cnt}} 개와 비교</span>
            <span v-else>다른 판매자 없음</span>)<br/>
            {{item.naver.rank === 1 ? 2 : 1}} 위 ({{$utils.comma(item.price + item.naver.price_diff)}} 원) 와의 가격차 {{$utils.comma(item.naver.price_diff)}} 원, {{item.naver.price_diff_per}} %
          </div>

          <div class="d-flex mb-3">
            <div class="flex-grow-1 w-100 cf_img">
              <small>이미지</small>
              <i v-if="editMode" class="ml-1 fa fa-pencil pointer" @click="setField('img_urls')"></i>
              <br/>
              <div>
                <template v-if="item.images">
                  <span v-for="(i, idx) in item.images">
                    <img :id="`imgs-${idx}`" :src="`https://i.balaan.io/${item.image_path}${i.thumb_200}`" style="height:100px"
                         @click="utils.open(`https://i.balaan.io/${item.image_path}${i.webp || i.jpg}`)"/>
                    <b-popover :target="`imgs-${idx}`" placement="bottom" triggers="hover focus">
                      <img :src="`https://i.balaan.io/${item.image_path}${i.thumb_300 || i.webp || i.org}`" style="max-width:300px"/>
                    </b-popover>
                  </span>
                </template>
                <template v-else>
                  <span v-for="(i, idx) in item.img_urls">
                    <img :id="`imgs-${idx}`" :src="i" style="height:100px" @click="utils.open(`${i}`)"/>
                    <b-popover :target="`imgs-${idx}`" placement="bottom" triggers="hover focus">
                      <img :src="i" style="max-width:250px"/>
                    </b-popover>
                  </span>
                </template>
              </div>
              <a :href="`/#/pages/image/confirmed/${item.goods_no}`" class="btn btn-primary btn-sm mt-1" target="_blank" v-if="item.image_path">이미지 보기</a>
            </div>
            <div v-if="epImageAsis" class="flex-grow-0">
              <small>EP 이미지<br/>(수동 크롭시)</small><br/>
              <img id="ep-image-asis" :src="epImageAsis" style="max-width:100px; border:1px solid #eee" @click="utils.open(epImageAsis)"/>
              <b-popover target="ep-image-asis" placement="bottom" triggers="hover focus">
                <img :src="epImageAsis" style="max-width:300px"/>
              </b-popover>
            </div>
            <div v-if="epImage" class="flex-grow-0">
              <small v-if="epImage.includes('_square')">현재 EP 이미지<br>(자동크롭)</small>
              <small v-else-if="epImage.includes('ep-image.balaan.io') && epImage.includes('?n=3')">현재 EP 이미지<br>(수동크롭)</small>
              <small v-else>현재 EP 이미지<br>(기본)</small>
              <br/>
              <img id="ep-image" :src="epImage" style="max-width:100px; border:1px solid #eee" @click="utils.open(epImage)"/>
              <b-popover target="ep-image" placement="bottom" triggers="hover focus">
                <img :src="epImage" style="max-width:300px"/>
              </b-popover>
            </div>
          </div>

          <b-row>
            <b-col cols="8">
              <div class="title-sm">상품코드</div>
              <small>SKU : </small>
              <template v-if="item.sku_id">
                <a :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${(item.sku_id||'').replace(' ', '+')}`" target="_blank">
                  <b-badge variant="success">{{item.sku_id}} <i class="fa fa-external-link"></i></b-badge>
                </a>
                <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.sku_id)"><i class="fa fa-copy"></i></b-badge>
              </template>
              <i v-if="editMode" class="ml-1 fa fa-pencil pointer" @click="setField('sku_id')"></i><br/>
              <small>통합 SKU : </small>
              <template v-if="item.matched_sku_id">
                <a :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${(item.matched_sku_id||'').replace(' ', '+')}`" target="_blank">
                  <b-badge variant="success">{{item.matched_sku_id}} <i class="fa fa-external-link"></i></b-badge>
                </a>
                <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.matched_sku_id)"><i class="fa fa-copy"></i></b-badge>
              </template>
              <i v-if="editMode" class="ml-1 fa fa-pencil pointer" @click="setField('matched_sku_id')"></i>
              <i v-if="manualEditMode" class="ml-1 fa fa-pencil pointer" @click="setField('matched_sku_id')"></i><br/>
              <small>파트너 상품코드 : </small>
              <a v-if="item.crawl_url && item.crawl_url.startsWith('http')" :href="`/r?u=${encodeURIComponent(item.crawl_url)}`" target="_blank">
                <b-badge variant="info">{{ item.goods_id || 'link' }} <i class="fa fa-external-link"></i></b-badge>
              </a>
              <b-badge v-else variant="light">{{ item.goods_id }}</b-badge>
              <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.goods_id)"><i class="fa fa-copy"></i></b-badge>
              &nbsp;<i class="fa fa-question-circle" v-b-tooltip="'파트너 상품링크가 있다면 파란색으로 표시되며 클릭하면 새 창으로 띄워집니다.'"></i>
              <br/>
              <small>상품관리코드 : </small><b-badge variant="light">{{item.mng_code}}</b-badge>
              <b-badge v-if="item.mng_code" class="ml-1 pointer" variant="light" @click="copyText(item.mng_code)"><i class="fa fa-copy"></i></b-badge>
              <br/>
            </b-col>
            <b-col cols="4">
              <div class="title-sm">상품속성</div>
              <span :class="`badge alert-${item.delivery_type === '해외' ? 'success' : 'info'}`">
                {{ item.delivery_str }}
              </span>
              <span v-if="!item.orderMade && (!item.deliveryExtra || !item.deliveryExtra.includes('long'))" class="badge badge-light">{{ item.delivery_day }}일</span><br/>
              <template v-if="item.manual">
                <span class="badge alert-danger">파트너관리</span><br/>
              </template>
              <span v-html="item._badgeOnedayDelivery"></span>
              <span v-html="item._badgeTodayPick"></span>
              <span v-html="item._badgeOrderMade"></span>
              <span v-html="item._badgeDeliveryExtra"></span>
              <span v-html="item._badgeGift"></span>
              <span v-html="item._badgeStore"></span>
              <br v-if="item._badgeGift || item._badgeStore"/>
              <template v-if="item.goodsType === 'used'">
                <span class="badge alert-warning">빈티지</span><span class="badge badge-light">{{ item.usedGrade }}</span><br/>
              </template>
              <template v-if="item.hide_es === true">
                <b-badge variant="dark">검색엔진제외</b-badge><br/>
              </template>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div class="title-sm">마스터 매칭 정보</div>
              <small>마스터 ID : </small>
              <b-badge :variant="item.gm_id ? 'success' : 'secondary'">{{item.gm_id || '마스터 없음'}}</b-badge>
              <b-badge v-if="item.gm_id" class="ml-1 pointer" variant="light" @click="copyText(item.gm_id)"><i class="fa fa-copy"></i></b-badge>
              <br/>
              <small>매칭 상태 : </small>
              <b-badge v-if="item.gm_status" variant="info">{{$F.GM_STATUS_MAP[item.gm_status]}} ({{item.gm_status}})</b-badge>
              <b-badge v-else variant="secondary">매칭 대상</b-badge>
              <div v-if="gm && gm.minPriceAll">
                <small>발란추천가 : {{gm.minPriceAll.comma()}}</small>
              </div>
              <div v-if="item.b_rank">
                <small>
                  B Rank : <b>{{item.b_rank}} </b>
                  <span v-if="item.b_rank !== 1">(1 위와의 차이 : {{item.b_price_diff_per}} %)</span>
                  <b-badge v-if="item.gm_id" class="ml-1 pointer" title="랭킹 재계산" variant="light" @click="refreshRank()">
                    <i class="fa fa-refresh"></i>
                  </b-badge>
                </small>
              </div>
              <div v-if="item.b_rank_class">
                <small>B Rank Class : <b>{{item.b_rank_class}}</b></small>
              </div>
            </b-col>
            <b-col>
              <div class="title-sm">가격 설정 정보</div>
              <template v-if="item.price_table && item.price_table[0]">
                <div v-for="pt in [item.price_table[0]]" :key="pt._dt"><!-- 약식 변수를 쓰기 위해 -->
                  <div v-if="pt.sale.operateRate || pt.sale.partnerRate">
                    <small class="mr-1">할인할증:</small>
                    <b-badge variant="success">운영: {{pt.sale.operateRate || 0}} %</b-badge>
                    <b-badge class="ml-1" variant="info">파트너: {{pt.sale.partnerRate || 0}} %</b-badge>
                  </div>

                  <div v-if="pt.dynamicPricing">
                    <small class="mr-1">DynamicPricing:</small>
                    <b-badge v-if="pt.dynamicPricing.mode === 'negative'" variant="warning">미달성</b-badge>
                    <b-badge v-else-if="pt.dynamicPricing.mode === 'positive'" variant="success">달성함</b-badge>
                    <b-badge v-else variant="light">가격변경없음</b-badge>
                    <div class="pl-2">
                      <b-badge variant="light">기본가격: {{pt.dynamicPricing.localPrice}}</b-badge><br/>
                      <b-badge variant="light">발란추천가: {{pt.dynamicPricing.minPriceAll}}</b-badge><br/>
                      <b-badge variant="light">목표가: {{pt.dynamicPricing.targetPrice}}</b-badge><br/>
                      <b-badge variant="light">할인율: {{pt.dynamicPricing.discountRate}} %</b-badge><br/>
                      <b-badge variant="light">발란부담액: {{pt.dynamicPricing.discountBaseBalaan + pt.dynamicPricing.discountCondBalaan + pt.dynamicPricing.discountOnlyBalaan}}</b-badge><br/>
                      <b-badge variant="light">파트너부담액: {{pt.dynamicPricing.discountBasePartner + pt.dynamicPricing.discountCondPartner}}</b-badge><br/>
                    </div>
                  </div>

                  <div v-if="pt.partnerGrade">
                    <small class="mr-1">파트너등급제:</small>
                    <b-badge v-if="pt.partnerGrade.mode === 'negative'" variant="warning">추천가 미달성</b-badge>
                    <b-badge v-else-if="pt.partnerGrade.mode === 'positive'" variant="success">추천가 달성함</b-badge>
                    <b-badge v-else-if="pt.partnerGrade.mode === 'fixed'" variant="success">고정할인 (추천가 없음)</b-badge>
                    <b-badge v-else variant="light">가격변경없음 (추천가 이하)</b-badge>
                    <div class="pl-2">
                      <b-badge variant="light">기본가격: {{pt.partnerGrade.prePrice}}</b-badge><br/>
                      <template v-if="pt.partnerGrade.minPriceAll">
                        <b-badge variant="light">발란추천가: {{pt.partnerGrade.minPriceAll}}</b-badge><br/>
                      </template>
                      <b-badge variant="light">목표가: {{pt.partnerGrade.targetPrice}}</b-badge><br/>
                      <b-badge variant="light">할인율: {{pt.partnerGrade.discountRate}} %</b-badge><br/>
                      <b-badge variant="light">
                        발란부담액: {{pt.partnerGrade.discountBaseBalaan + pt.partnerGrade.discountCondBalaan + pt.partnerGrade.discountOnlyBalaan}}
                      </b-badge><br/>
                      <b-badge variant="light">파트너부담액: {{pt.partnerGrade.discountBasePartner + pt.partnerGrade.discountCondPartner}}</b-badge><br/>
                      <b-badge variant="light">1차할인 분담률: {{pt.partnerGrade.ratioByBrank * 100}} %</b-badge><br/>
                    </div>
                  </div>

                  <div v-if="pt.priceRebateRule">
                    <small class="mr-1">병행가격설정:</small>
                    <b-badge variant="light">{{pt.prRule.final_sale_price || pt.prRule.price}}</b-badge><br/>
                    <div class="pl-2">
                      <a href="/#/priceRebate?search=3038" target="_blank" class="badge alert-primary">
                        {{pt.priceRebateRule.page.no}}. {{pt.priceRebateRule.page.name}}
                      </a>
                    </div>
                  </div>
                </div>
              </template>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <div class="title-sm">예상수령일</div>
<!--              <small>배송유형 및 예상수령일</small><br/>
              <span :class="`badge alert-${item.delivery_type === '국내' ? 'info' : 'success'}`">{{ item.delivery_str }}</span>
              <span v-if="item.oneday_delivery === 'Y'" class="badge badge-danger ml-1">오늘도착 가능</span>
              <small class="mx-1">(평균 {{deliveryDay}} 영업일)</small>
              <i class="fa fa-question-circle" v-b-tooltip="`파트너 배송일 ${deliveryDay} 일에 주말 및 공휴일을 고려하여 +- 1일을 한 결과입니다`"></i>
              <br/>-->
              <template v-if="!item.orderMade && (!item.deliveryExtra || !item.deliveryExtra.includes('long'))">
                <span>오늘 ({{momentBiz().locale('ko').format('MM/DD ddd')}}) 주문시 </span>
                <b>
                  <span>{{momentBiz().businessAdd(deliveryDay, 'day').locale('ko').format('MM/DD(ddd)')}}</span>
                  ~ <span>{{momentBiz().businessAdd(deliveryDay + 2, 'day').locale('ko').format('MM/DD(ddd)')}}</span>
                </b>
                수령예상
              </template>
              <small v-else>{{item.orderMade ? '주문제작' : item.deliveryExtra && item.deliveryExtra.includes('long') ? '가구배송' : ''}} 상품의 배송일정은 상품 상세페이지에서 확인해주세요.</small>
            </b-col>
            <b-col>
              <div class="title-sm">
                교환/반품 정보
                <i class="fa fa-question-circle" v-b-tooltip="'상품 등록시 상품별 반품비에 반품비가 입력된 경우 노출됩니다. 등록 되지 않은 경우 SHOP 정보에 등록 된 교환/반품비가 적용 됩니다.'"></i>
              </div>
              <div>
                반품 배송비 : {{ $utils.comma(return_cost) }} 원
                <span :class="`badge alert-${return_cost_type !== 'goods' ? 'success' : 'primary'}`">{{ return_cost_type_str }}{{' 반품 배송비'}}</span>
              </div>
              <div>
                교환 배송비 : {{ $utils.comma(exchange_cost) }} 원
                <span :class="`badge alert-${exchange_cost_type !== 'goods' ? 'success' : 'primary'}`">{{ exchange_cost_type_str }}{{' 교환 배송비'}}</span>
              </div>
            </b-col>
            <!--<b-col cols="6">
              <small>오늘도착여부</small> <i v-if="editMode" class="ml-1 fa fa-pencil pointer" @click="setField('oneday_delivery')"></i><br/>
              {{item.oneday_delivery === 'Y' ? 'Y' : 'N'}}
            </b-col>-->
          </b-row>

          <b-row class="mb-3" v-if="item.display_status === 'notview' && item.display_status_reason">
            <b-col cols="12">
              <span class="badge badge-warning">상품 미노출 사유</span><br/>
              <span :title="item._notview_dt">[{{reduceDT(item._notview_dt)}}]&nbsp;</span>
              <span v-html="item.display_status_reason"></span>
              <b-badge class="ml-1" variant="info">by {{item._notview_by}}</b-badge>
            </b-col>
          </b-row>

          <div class="title-sm">상품정보</div>
          <b-row class="mb-3">
            <b-col cols="6" lg="3">
              <small>컬러</small> <i v-if="editMode" class="ml-1 fa fa-pencil pointer" @click="setField('color')"></i><br/>
              {{item.color || '-'}}
            </b-col>
            <b-col cols="6" lg="3">
              <small>컬러 자동매칭(major_color)</small><br/>
              <template v-if="item.major_color && colorMap[item.major_color]">
                <div class="d-inline-block" :style="{backgroundColor:'#'+colorMap[item.major_color].color, width:'22px', height:'22px'}"></div>
                <div class="d-inline-block ml-1" style="vertical-align: super">{{item.major_color}}</div>
              </template>
              <span v-else>-</span>
            </b-col>
            <b-col cols="6" lg="3">
              <small>시즌</small> <i v-if="editMode" class="ml-1 fa fa-pencil pointer" @click="setField('season')"></i><br/>
              {{item.season || '-'}}
            </b-col>
            <b-col class="mt-sm-2 mt-lg-0" cols="6" lg="3">
              <small>원산지</small> <i v-if="editMode" class="ml-1 fa fa-pencil pointer" @click="setField('origin')"></i><br/>
              {{item.origin || '-'}}
            </b-col>
            <b-col cols="6" sm="3">
              <div class="label-sm">최초생성위치</div>
              <span v-html="badgeCat(item._cat)"></span>
            </b-col>
            <b-col cols="6" sm="3">
              <div class="label-sm">최초생성시각</div>
              {{ item._cdt || '-' }}
            </b-col>
            <b-col cols="6" sm="3">
              <div class="label-sm">최초송출시각(registered)</div>
              {{ item.registered_dt || '-' }}
            </b-col>
            <b-col cols="6" sm="3">
              <div class="label-sm">최근수정시각</div>
              {{ item._mdt || '-' }}
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col cols="12">
              <small>소재</small> <i v-if="editMode" class="ml-1 fa fa-pencil pointer" @click="setField('composition')"></i><br/>
              <span v-html="item.composition || '-'"></span>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col cols="12">
              <small>measurements</small> <i v-if="editMode" class="ml-1 fa fa-pencil pointer" @click="setField('measurements')"></i><br/>
              <span v-html="item.measurements || '-'"></span>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col class="cf_desc" cols="12">
              <small>설명 (본문의 이미지는 편의상 300px 로 제한됨, 실제로는 꽉차게 보임)</small> <i v-if="editMode" class="ml-1 fa fa-pencil pointer" @click="setField('description')"></i><br/>
              <div ref="desc" class="mh-600" v-html="item.description || '-'"></div>
              <b-button block variant="outline-light" v-if="$refs.desc && $refs.desc.offsetHeight === 600"
                        @click="e => { e.target.previousSibling.classList.remove('mh-600'); e.target.classList.add('d-none')}">Show More...</b-button>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col cols="6" lg="3">
              <small>최초등록일(processing)</small><br/>
              {{item.created_dt}}
            </b-col>
            <b-col cols="6" lg="3">
              <small>최초송출일(registered)</small><br/>
              {{item.registered_dt}}
            </b-col>
            <b-col cols="6" lg="3">
              <small>최근 가격변동</small><br/>
              <span v-if="item.price_diff">
                {{item.price_diff._dt}} <i class="fa fa-question-circle" v-b-tooltip="`${item.price_diff.price.updated_from} ⇒ ${item.price_diff.price.updated_to}`"></i>
              </span>
              <span v-else>
                -
              </span>
            </b-col>
            <b-col cols="6" lg="3">
              <small>최근 재고변동</small><br/>
              <span v-if="item.stock_diff">
                {{item.stock_diff._dt}} <i class="fa fa-question-circle" v-b-tooltip="`${item.stock_diff.tot_stock.updated_from} ⇒ ${item.stock_diff.tot_stock.updated_to}`"></i>
              </span>
              <span v-else>
                -
              </span>
            </b-col>
          </b-row>

          <h5 v-if="$R('GOODS_R') && item.mapped && item.mapped.src_not_found">
            <small class="text-muted"><i class="fa fa-exclamation-circle"></i> {{item.mapped.not_found._dt}} 이후로 원본정보에서 찾을 수 없습니다</small>
          </h5>

          <template v-if="item.mapped && item.mapped.consignObj && PRIVATE">
            <div class="title-sm">위탁 정보</div>
            <template v-for="(obj, idx) in [item.mapped.consignObj]">
              <b-row class="mb-3" :key="idx">
                <b-col cols="6" lg="3">
                  <small>위탁 지점명</small><br/>
                  {{obj.branch_name || '-'}}
                </b-col>
                <b-col cols="6" lg="3">
                  <small>위탁 판매자 아이디</small><br/>
                  {{obj.seller_id || '-'}}
                </b-col>
                <b-col cols="6" lg="3">
                  <small>위탁 확인서</small><br/>
                  <a class="d-block ellipsis c-black" :href="`/goods/consign/download/${obj.confirmation_url}`" target="_blank" :title="obj.confirmation_url">
                    <i class="fa fa-file-o"></i> {{obj.confirmation_url && obj.confirmation_url.split('/').slice(-1)[0] || '-'}}
                  </a>
                </b-col>
                <!--
                <b-col cols="6" lg="3">
                  <small>위탁 판매자 신분증 사본</small><br/>
                  <a class="d-block ellipsis c-black" :href="`/goods/consign/download/${obj.id_card_url}`" target="_blank" :title="obj.id_card_url">
                    <i class="fa fa-file-o"></i> {{obj.id_card_url && obj.id_card_url.split('/').slice(-1)[0] || '-'}}
                  </a>
                </b-col>
                -->
                <b-col cols="6" lg="3">
                  <small>은행명</small><br/>
                  {{obj.bank_name || '-'}}
                </b-col>
                <b-col cols="6" lg="3">
                  <small>계좌번호</small><br/>
                  {{obj.account_number || '-'}}
                </b-col>
                <b-col cols="6" lg="3">
                  <small>예금주</small><br/>
                  {{obj.owner_name || '-'}}
                </b-col>
              </b-row>
            </template>
          </template>


          <div class="clearfix">
            <b-button v-if="$R('GOODS_R') && item.mapped" class="mr-1 mb-1" size="sm" variant="light" v-b-toggle.mapped>원본정보 확인</b-button>
            <b-button v-if="$R('GOODS_R') && item.price_table && item.price_table.length" class="mr-1 mb-1" size="sm" variant="light" @click="$modal.show({title:'원가테이블 확인', type:'json', item:item.price_table, fieldMap:$FM['price_table']})">
              원가정보 확인<span v-if="item.price_table.length > 1">({{item.price_table.length}} 종류)</span>
            </b-button>
            <template v-if="collection !== 'terminated'">
              <b-button class="mr-1 mb-1" size="sm" variant="info" :disabled="busy.updatePriceTable" @click="updatePriceTable"><b-spinner class="mr-2" small v-if="busy.updatePriceTable"></b-spinner>가격 재계산</b-button>
<!--              <b-button class="mr-1 mb-1" size="sm" variant="success" :disabled="busy.stockPick" @click="stockPick"><b-spinner class="mr-1" small v-if="busy.stockPick"></b-spinner>실시간 재고</b-button>-->
              <b-button class="mr-1 mb-1" size="sm" variant="primary" :disabled="busy.syncStock" @click="syncStock"><b-spinner class="mr-2" small v-if="busy.syncStock"></b-spinner>재고 갱신</b-button>
              <b-button class="mr-1 mb-1" size="sm" variant="primary" :disabled="busy.getES" @click="getES"><b-spinner class="mr-2" small v-if="busy.getES"></b-spinner>발란몰 ES</b-button>
              <b-button class="mr-1 mb-1" size="sm" variant="light" :disabled="busy.syncES" @click="syncES"><b-spinner class="mr-2" small v-if="busy.syncES"></b-spinner>갱신</b-button>
              <b-button class="mr-1 mb-1" size="sm" variant="success" :disabled="busy.getCfES" @click="getCfES"><b-spinner class="mr-2" small v-if="busy.getCfES"></b-spinner>HUB ES</b-button>
              <b-button class="mr-1 mb-1" size="sm" variant="light" :disabled="busy.syncCfES" @click="syncCfES"><b-spinner class="mr-2" small v-if="busy.syncCfES"></b-spinner>갱신</b-button>
              <b-button v-if="item.diffCfES" class="mr-1 mb-1" size="sm" variant="warning" @click="jsonModal('CF vs ES Diff', item.diffCfES)">Diff</b-button>
            </template>
            <div class="pull-right">
              <b-button v-if="!item.manual && collection !== 'terminated'" class="" size="sm" :variant="editMode ? 'dark' : 'outline-dark'" @click="editMode = !editMode"><i class="fa fa-pencil"></i></b-button>
              <b-button v-if="item.manual && collection !== 'terminated'" class="" size="sm" :variant="manualEditMode ? 'dark' : 'outline-dark'" @click="manualEditMode = !manualEditMode"><i class="fa fa-pencil-square-o"></i></b-button>
            </div>
          </div>

          <b-collapse id="mapped" v-if="$R('GOODS_R') && item.mapped">
            <hr/>
            <div class="clearfix">
              <b-button class="float-right" v-if="item.mapped._diff && item.mapped._diff.length" variant="outline-light" @click="showDiffModal(item.mapped)">
                수정이력
              </b-button>
              <h5>
                Mapped {{item.mapped._coll === 'mapped_removed' ? 'Removed' : ''}}
                <small v-if="item.mapped.src_not_found" class="text-muted">
                  <i class="fa fa-exclamation-circle"></i> {{item.mapped.not_found._dt}} 이후로 원본정보에서 찾을 수 없습니다
                </small>
              </h5>
            </div>
            <div class="mb-3">
              <b-badge variant="success">{{item.mapped.shop_id}}. {{item.mapped.shop}}</b-badge>
              <b-badge class="ml-1" variant="warning">{{item.mapped.brand_name}}</b-badge>
              <b-badge class="ml-1" variant="secondary">{{item.mapped.category}}</b-badge>
              <b-badge class="ml-1" variant="light">{{item.mapped.origin_category}}</b-badge><br/>
              {{item.mapped.name}}
            </div>
            <div class="mb-4 mp_img">
              <template v-if="item.mapped.imageReady">
                <small>일반 이미지 ({{item.mapped.images.length}} 개)</small><br/>
                <div class="mt-2">
                  <span v-for="(i, idx) in item.mapped.images">
                    <img :id="`mapped_images-${idx}`" :src="`https://i.balaan.io/${item.mapped.image_path}${i.thumb_200}`" style="height:100px"
                         @click="utils.open(`https://i.balaan.io/${item.mapped.image_path}${i.webp || i.jpg}`)"/>
                    <b-popover :target="`mapped_images-${idx}`" placement="bottom" triggers="hover focus">
                      <img :src="`https://i.balaan.io/${item.mapped.image_path}${i.thumb_300 || i.org}`" style="max-width:300px"/>
                    </b-popover>
                  </span>
                </div>
                <div class="mt-3" v-if="item.mapped.longImages">
                  <small>긴 이미지로 분류된 이미지 ({{item.mapped.longImages.length}} 개)</small><br/>
                  <div class="mt-2">
                    <span v-for="(i, idx) in item.mapped.longImages">
                      <div style="width: 100px; max-height: 100px; overflow: hidden">
                        <img :id="`mapped_longImages-${idx}`" :src="`https://i.balaan.io/${item.mapped.image_path}${i.org_webp || i.org}`" style="width: 100%"
                             @click="utils.open(`https://i.balaan.io/${item.mapped.image_path}${i.org_webp || i.org}`)"/>
                      </div>
                      <b-popover :target="`mapped_longImages-${idx}`" placement="bottom" triggers="hover focus">
                        <img :src="`https://i.balaan.io/${item.mapped.image_path}${i.thumb_300 || i.org}`" style="max-width:300px"/>
                      </b-popover>
                    </span>
                  </div>
                </div>
                <a :href="`/#/pages/image/mapped/${encodeURIComponent(item.mapped.gid)}`" class="btn btn-primary btn-sm mt-1" target="_blank" v-if="item.image_path">이미지 보기</a>
              </template>
              <template v-else>
                <small>이미지</small><br/>
                <div v-if="item.shop_id != 46" class="mt-2">
                  <span v-for="(i, idx) in item.img_urls">
                    <img :id="`mapped_imgs-${idx}`" :src="i" style="height:100px" @click="utils.open(`${i}`)"/>
                    <b-popover :target="`mapped_imgs-${idx}`" placement="bottom" triggers="hover focus">
                      <img :src="i" style="max-width:300px"/>
                    </b-popover>
                  </span>
                </div>
                <div v-else>
                  46. OKMALL 은 이미지를 가져오지 않습니다
                </div>
              </template>
            </div>
            <b-row class="mb-3">
              <b-col cols="6" lg="3">
                <small>가격</small><br/>
                <div class="text-right pr-3">
                  <div v-if="item.mapped.discount_rate > 0"><del>{{utils.rnc(item.mapped.price / (100-item.mapped.discount_rate) * 100, 2)}}</del> {{item.mapped.price_unit}}</div>
                  <div>{{utils.rnc(item.mapped.price, 2)}} {{item.mapped.price_unit}}</div>
                  <div v-if="item.mapped.discount_rate > 0">({{item.mapped.discount_rate}} %)</div>
                </div>
              </b-col>
              <b-col cols="6" lg="4">
                <div v-if="item.mapped.options && item.mapped.options.length">
                  <small>{{item.mapped.options[0].optnm}}</small><br/>
                  <template v-for="e in item.mapped.options">
                    <b-badge variant="light">{{e.Size}}</b-badge><b-badge variant="success">{{e.stock}}</b-badge>
                    &nbsp;
                    <span v-if="e.goods_price !== item.mapped.price"><small>{{utils.rnc(e.goods_price, 2)}} {{item.mapped.price_unit}} ({{utils.delta(e.goods_price - item.mapped.price, 2)}} {{item.mapped.price_unit}})</small></span>
                    <span v-else class="text-muted"><small>(가격동일)</small></span>
                    <br/>
                  </template>
                </div>
              </b-col>
              <b-col cols="6" lg="5">
                <small>상품코드</small><br/>
                <small>SKU : </small><a :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${(item.mapped.sku_id||'').replace(' ', '+')}`" target="_blank"><b-badge variant="success">{{item.mapped.sku_id}}</b-badge></a><br/>
                <small>파트너 상품코드 : </small>
                <a v-if="item.mapped.crawl_url && item.mapped.crawl_url.startsWith('http')" :href="`/r?u=${encodeURIComponent(item.mapped.crawl_url)}`" target="_blank"><b-badge variant="info">{{item.mapped.goods_id || 'link'}}</b-badge></a>
                <b-badge v-else variant="light">{{item.mapped.goods_id}}</b-badge>&nbsp;<i class="fa fa-question-circle" v-b-tooltip="'파트너 상품링크가 있다면 파란색으로 표시되며 클릭하면 새 창으로 띄워집니다. OKMALL은 차단되어 있으니 링크를 복사하여 vpn이나 proxy를 이용 바랍니다.'"></i>
                <br/>
                <small>상품관리코드 : </small><b-badge variant="light">{{item.mapped.mng_code}}</b-badge>
                <b-badge v-if="item.mapped.mng_code" class="ml-1 pointer" variant="light" @click="copyText(item.mapped.mng_code)"><i class="fa fa-copy"></i></b-badge>
                <br/>
                <template v-if="item.mapped.oneday_delivery === 'Y'">
                  <span class="badge alert-danger mr-1">오늘도착</span><small>마감시간: 13:00</small><br/>
                </template>
                <template v-if="item.mapped.today_pick">
                  <span class="badge alert-success mr-1">오늘출발</span><small>마감시간: {{item.mapped.today_pick_time}}</small><br/>
                </template>
                <b-badge v-if="item.mapped.gift_packing" class="mr-1" variant="danger">포장</b-badge>
                <b-badge v-if="item.mapped.store_pickup" variant="primary">픽업</b-badge>
                <br v-if="item.mapped.gift_packing || item.mapped.store_pickup"/>
                <template v-if="item.hide_es === true">
                  <b-badge variant="dark">검색엔진제외</b-badge><br/>
                </template>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="3">
                <small>컬러</small><br/>
                {{item.mapped.color || '-'}}
              </b-col>
              <b-col cols="3">
                <small>시즌</small><br/>
                {{item.mapped.launch_date || '-'}}
              </b-col>
              <b-col cols="3">
                <small>원산지</small><br/>
                {{item.mapped.origin || '-'}}
              </b-col>
              <b-col cols="3">
                <small>성별</small><br/>
                {{item.mapped.generation || '-'}}
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="3">
                <small>배송유형</small><br/>
                {{item.mapped.delivery_type || '-'}}
              </b-col>
              <b-col cols="9">
                <small>소재</small><br/>
                <span v-html="item.mapped.composition || '-'"></span>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12">
                <small>measurements</small><br/>
                <span v-html="item.mapped.measurements || '-'"></span>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12">
                <small>modelsize</small><br/>
                <span v-html="item.mapped.modelsize || '-'"></span>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col class="mp_desc" cols="12">
                <small>설명 <span v-if="item.mapped.longImages">(상품이 등록될 때 긴 이미지가 포함되게 됨)</span></small><br/>
                <div ref="mp_desc" class="mh-600" v-html="item.mapped.description || '-'"></div>
                <b-button block variant="outline-light" v-if="$refs.mp_desc && $refs.mp_desc.offsetHeight === 600"
                          @click="e => { e.target.previousSibling.classList.remove('mh-600'); e.target.classList.add('d-none')}">Show More...</b-button>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="6" lg="3">
                <small>최초등록일</small><br/>
                {{item.mapped._cdt}}
              </b-col>
              <b-col cols="6" lg="3">
                <small>최근확인시각</small><br/>
                {{item.mapped._dt}}
              </b-col>
            </b-row>
            <b-button v-if="$R('GOODS_R') && item.mapped" class="mr-1" variant="light" @click="jsonModal('Mapped 보기', item.mapped)">Mapped JSON</b-button>
            <b-button v-if="$R('GOODS_R') && item.src" class="mr-1" variant="light" @click="jsonModal('Src 보기', item.src)">SRC JSON</b-button>
            <!--            <b-button v-if="$R('GOODS_X')" class="mr-1" variant="primary" @click="imageProcessing(item.goods_no)">이미지 재처리</b-button>-->
            <b-button v-if="$R('GOODS_R') && itemAttr.godo" class="mr-1" variant="dark" @click="jsonModal('JSON 보기', itemAttr.godo)">발란몰</b-button>
            <b-button v-if="$R('GOODS_R') && itemAttr.ss" class="mr-1" variant="dark" @click="jsonModal('JSON 보기', itemAttr.ss)">스마트스토어</b-button>
            <b-button v-if="$R('GOODS_R')" class="mr-1" variant="success" @click="getMpES()">검색엔진</b-button>
            <b-button v-if="$R('DEV')" class="mr-1" variant="outline-dark" @click="statData">statData</b-button>
            <b-button v-if="$R('DEV')" class="mr-1" variant="outline-dark" @click="epData">epData</b-button>
            <b-button v-if="$R('DEV')" class="mr-1" variant="outline-dark" @click="webStore">webStore</b-button>
            <!--<h5>Src</h5>-->
          </b-collapse>
        </b-col>
        <b-col class="border-left" cols="12" md="4">
          <label>외부몰 등록이력</label>
          <div class="mb-1" v-for="(s,idx) in item.links">  <!--v-b-popover.hover.right="i.msg"-->
            <b-badge variant="primary">{{s.mall}}</b-badge>&nbsp;
            <a v-if="targetMap[s.mall]" :href="`${$C.MALL_GOODS_LINK[targetMap[s.mall]]}${s.mall_id}`" target="_blank" class="badge badge-info">{{s.mall_id}}</a>
            <b-badge v-else variant="secondary">{{s.mall_id}}</b-badge>&nbsp;
            <b-badge variant="light">{{s._cdt}}</b-badge>
          </div>
          <div v-if="$R('DEV') && item.delivery_type === '국내' && item.category < '012' && item.price_table && item.price_table.length">
            <b-badge variant="warning">DEV</b-badge>
            Shopify 예상가격 : {{ $utils.floor(item.price_table[0].goodsSettlePrice * (100 + shopifyPricing.opRate1 + shopifyPricing.opRate2) / 100 / shopifyPricing.usdkrw, 2) }} USD
            <a :href="`https://balaan.com/search?q=${item.goods_no}&options%5Bprefix%5D=last`" target="_blank">[검색]</a>
          </div>
          <hr/>
          <label>상품 판매이력 ({{orders.length}} 개)</label>
          <perfect-scrollbar v-if="orders.length" style="max-height:400px;overflow-y:auto;overflow-x:hidden">
            <span v-for="(ord,idx) in orders">  <!--v-b-popover.hover.right="i.msg"-->
              <div class="message">
                <div class="py-3 pb-5 mr-3 float-left" style="max-height:60px">
                  <div class="avatar">
                    <img :id="`ord-img-${idx}`" :src="ord.img" class="img-avatar" @click="utils.open(ord.img)">
                    <!--<b-badge variant="danger" class="avatar-status"></b-badge>-->
                    <b-popover :target="`ord-img-${idx}`" placement="left" triggers="hover focus">
                      <img :src="ord.img" style="max-width:250px"/>
                    </b-popover>
                  </div>
                </div>
                <div>
                  <template v-if="PRIVATE">
                    <a :href="`/#/deliveryBoard/${ord.orderno}/${ord.order_detailno}`" target="_blank"><small class="text-muted">[{{ord.mall}}] {{ord.orderno}}:{{ord.order_detailno}}</small></a><br/>
                  </template>
                  <b-badge variant="primary">{{statusCodeMap[ord.order_status_code].name}}</b-badge>
                  <small class="text-muted ml-1 mt-1">(주문: {{ord.order_date}})</small>
                  <small class="text-muted float-right mt-1">{{ord[ord.order_status_code+'_date']}}</small>
                </div>
                <div class="font-weight-bold text-truncate">{{ord.goodsnm}}</div>
                <div>
                  <b-badge variant="light">{{ord.size}}</b-badge>&nbsp;
                  <small>{{ord.qty}} 개</small>&nbsp;
                  <b-badge variant="light">{{ord.sales_price}}</b-badge>
                </div>
              </div>
              <hr>
            </span>
          </perfect-scrollbar>
          <div v-else>
            <div class="my-3 text-center font-weight-bold">상품 판매이력이 없습니다</div>
          </div>
          <div v-if="reviews.length" class="mt-3">
            <label>상품리뷰 ({{reviews.length}} 개)</label>
            <perfect-scrollbar v-if="reviews.length" style="max-height:400px;overflow-y:auto;overflow-x:hidden">
              <div v-for="(e, idx) in reviews" :key="idx">
                <div class="d-flex flex-row">
                  <a v-if="e.imgs[0]" :href="webp(e.imgs[0])" class="flex-grow-0" target="_blank">
                    <img :id="`review-img-${idx}`" class="w-65px" :src="webp(e.imgs[0], {thumb: 400})" />
                  </a>
                  <div class="flex-grow-1 pl-2">
                    <div>
                      <span>
                        <i v-for="_ in Array(e.point)" class="fa fa-star" :key="_"></i>
                        <i v-for="_ in Array(5-e.point)" class="fa fa-star-o" :key="_"></i>
                      </span>
                      <small class="text-muted float-right mt-1">{{momentBiz(e.regdt).format('YYYY-MM-DD HH:mm')}}</small>
                    </div>
                    <div class="font-weight-bold">{{e.subject}}</div>
                    <div class="fs-12" style="display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; overflow: hidden;">{{e.contents}}</div>
                  </div>
                </div>
                <b-popover :target="`review-img-${idx}`" placement="left" triggers="hover focus">
                  <img :src="webp(e.imgs[0], {thumb: 400})" style="max-width:250px" />
                </b-popover>
                <hr>
              </div>
            </perfect-scrollbar>
          </div>

          <div v-if="cartWish.length" class="mt-3">
            <label>쇼핑백 &amp; 위시리스트 ({{cartWish.length}} 개)</label>
            <perfect-scrollbar v-if="cartWish.length" style="max-height:400px;overflow-y:auto;overflow-x:hidden">
              <div v-for="(e, idx) in cartWish" :key="idx">
                <div class="clearfix">
                  <small class="text-muted float-right mt-1">{{momentBiz(e.regdt).format('YYYY-MM-DD HH:mm')}}</small>
                  <b-badge class="mr-2" :variant="e.type === 'wish' ? 'primary' : 'success'">{{e.type}}</b-badge>
                  <div class="font-weight-bold d-inline-block fs-12 ellipsis w-200px" style="vertical-align: middle">{{e.m_id || '(ID 없음)'}}</div>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </b-col>
      </b-row>
      <template v-slot:modal-footer="{ ok }">
        <b-button v-if="$R(['DEV'])" variant="outline-light" @click="priceDiffModal = true">
          가격변화
        </b-button>
        <b-button v-if="$R(['GOODS_X', 'DEV'])" variant="outline-light" @click="shopifySendGoods()">
          Shopify 전송
        </b-button>
        <b-button v-if="$R('GOODS_X')" variant="outline-light" @click="jsonModal('JSON 보기', item)">
          JSON
        </b-button>
<!--        <b-button v-if="item._diff && item._diff.length" variant="outline-secondary" @click="optionDiffXlsx()">
          Option 이력
        </b-button>-->
        <b-button v-if="item._diff && item._diff.length" variant="outline-light" @click="showDiffModal(item)">
          수정이력
        </b-button>
        <b-button v-if="item._del_dt" variant="outline-danger" @click="$modal.show({type: 'del', item});">
          삭제이력
        </b-button>
        <b-button variant="primary" @click="ok()">
          닫기
        </b-button>
      </template>
    </b-modal>

    <b-modal title="필드값 수정" size="lg" v-model="modal.field" @ok="setField(fieldModalName, true)">
      <small>{{fieldModalName}} 의 값</small><br/>
      <b-alert v-model="fieldModalImageWarn" size="sm" variant="danger">원본이미지와 수가 다릅니다. 원본의 순서도 확인해주세요</b-alert>
      <b-textarea class="w-100" v-model="fieldModalValue" rows="10"></b-textarea>
      <b-alert show size="sm" variant="info">필드의 값은 발란몰로 바로 전파됩니다</b-alert>
    </b-modal>


    <b-modal title="가격 변화 차트" size="xl" v-model="priceDiffModal">
      <div id="chart">
        <apexchart type="candlestick" height="350" :options="chartOptions" :series="series"></apexchart>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import * as utils from '@/shared/utils'
import {down} from '@/shared/impexp'
import {Types} from "mongoose";
import GoodsMixin from '@/views/goods/GoodsMixin'

export default {
  name: "ConfirmedModal",
  props: ['modal', 'momentBiz', 'shopMap', 'targetMap', 'collection'],
  mixins: [
    GoodsMixin
  ],
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      utils,
      colorMap: this.$utils.arr2map(this.$C.COLORS, 'name'),
      statusCodeMap: this.$utils.arr2map(this.$C.ORDER_STATUS_CODE, 'code'),
      editMode: false,
      manualEditMode: false,
      deliveryDay: 6,
      item: {},
      itemAttr: {},
      gm: null,
      orders: [],
      reviews: [],
      cartWish: [],
      fieldModalName: '',
      fieldModalType: '',
      fieldModalValue: '',
      fieldModalImageWarn: false,
      epImageAsis: null,
      epImage: null,

      return_cost_type: '',
      return_cost_type_str: '',
      exchange_cost_type: '',
      exchange_cost_type_str: '',
      return_cost: '',
      exchange_cost: '',

      // shopify
      shopifyPricing: {},

      busy: {
        stockPick: false,
        syncStock: false,
        getES: false,
        syncES: false,
        getCfES: false,
        syncCfES: false,
        getMpES: false,
        updatePriceTable: false,
        statData: false,
        epData: false,
        webStore: false
      },

      priceDiffModal: false,
      series: [{
        data: []
      }],
      chartOptions: {
        chart: {
          type: 'candlestick',
          height: 350
        },
        title: {
          text: '가격 변화 차트',
          align: 'left'
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: val => {
              return this.$moment(val).format('YY-MM-DD')
            }
          }
        },
        yaxis: {
          tooltip: {
            enabled: true
          }
        }
      },
    };
  },
  computed:{
  },
  created() {
    // colorMap gray 보정
    this.colorMap.gray = this.colorMap.grey;
  },
  methods: {
    showModal(row) {
      this.editMode = false;
      this.manualEditMode = false;
      let item = this.item = row.item;
      // 상품에 따라 변경될 수 있도록 - 수정필요
      if (!item || !item.goods_no) return this.modal.detail = false;

      // 가격 변화 차트
      this.makeSeriesFromDiff();

      const shop = this.shopMap[item.shop_id];

      let deliveryDay = +(shop && shop.delivery_day || 6);
      if (shop) {
        if (shop.delivery_type === 'both') {
          if (item.delivery_type === '국내') {
            deliveryDay = shop.delivery_day;
          } else {
            deliveryDay = shop.delivery_day_abroad || 6;
          }
        }
      }

      this.deliveryDay = deliveryDay;
      this.orders = [];
      this.reviews = [];
      this.cartWish = [];
      this.itemAttr = {};

      // 교환/반품비 정비
      // shop 별, 상품별을 고려하여 반품비 계산
      const costStrMap = {shop: 'SHOP 별', goods: '상품별'};
      const shopReturnCost = shop.delivery_type === 'both' && item.delivery_type === '해외' ? shop.return_cost_abroad : shop.return_cost;
      const shopExchangeCost = shop.delivery_type === 'both' && item.delivery_type === '해외' ? shop.exchange_cost_abroad : shop.exchange_cost;
      this.return_cost_type = shop.return_cost_type;
      this.return_cost_type_str = costStrMap[shop.return_cost_type] || 'SHOP 별';
      this.exchange_cost_type = shop.exchange_cost_type;
      this.exchange_cost_type_str = costStrMap[shop.exchange_cost_type] || 'SHOP 별';
      if (shop.return_cost_type !== 'goods') {
        this.return_cost = shopReturnCost;
      } else if (shop.return_cost_type === 'goods') { // 상품별
        if (item.return_cost != null) {
          this.return_cost = item.return_cost;
        } else { // 상품별 반품비가 없다면 shop 의 것을 쓴다.
          this.return_cost = shopReturnCost;
          this.return_cost_type_str = '상품별 설정 없음, SHOP 별';
        }
      }
      if (shop.exchange_cost_type !== 'goods') {
        this.exchange_cost = shopExchangeCost;
      } else if (shop.exchange_cost_type === 'goods') { // 상품별
        if (item.exchange_cost != null) {
          this.exchange_cost = item.exchange_cost;
        } else { // 상품별 교환비가 없다면 shop 의 것을 쓴다.
          this.exchange_cost = shopExchangeCost;
          this.exchange_cost_type_str = '상품별 설정 없음, SHOP 별';
        }
      }

      // _diff 가 있다면 최근 가격변경, 재고변경일을 가져온다
      if (item._diff && item._diff.length) {
        item.stock_diff = item._diff.filter(e=>e.tot_stock).reverse()[0];
        item.price_diff = item._diff.filter(e=>e.price).reverse()[0];
      }

      // 상세 정보를 받아온다.
      this.$api.getJson('/goods/detail?goods_no=' + item.goods_no).then(j => {
        if (!j) return;
        const {price_table, soOptMap, mp, srcs, epImageUrl, gm, gdGoods, orders, reviews, cartWish} = j;

        this.item.price_table = price_table;
        // this.item.options.forEach(e => {
          // if (soOptMap[e.Size]) e.soldout = 1;
        // });
        if (this.$R('GOODS_R')) {
          if (mp) {
            this.item.mapped = mp;
            this.item.mapped._cdt = utils.kstDT(Types.ObjectId(this.item.mapped._id).getTimestamp());
            if (this.item.mapped._diff) {
              this.item.mapped.not_found = this.item.mapped._diff.filter(e => e.src_not_found || e._desc === 'src_not_found: true').reverse()[0];
            }
          }
          this.item.src = srcs;
        }

        this.epImage = epImageUrl;
        this.epImageAsis = epImageUrl;
        if (epImageUrl.includes('_square')) {
          this.epImageAsis = epImageUrl.replace('i.balaan.io', 'ep-image.balaan.io').replace('_square', '') + '?n=3';
        } else if (epImageUrl.includes('ep-image.balaan.io')) {
          this.epImageAsis = epImageUrl.replace('?n=2', '?n=3');
        }

        if (this.item.goods_status !== 'processing') {
          this.gm = gm;
          if (this.$R('GOODS_R')) this.itemAttr.godo = gdGoods;
          orders.forEach(e => {
            if (e.mall === '고도몰') e.mall = '발란몰';
            e.img = e.img_urls[0];
          });
          this.orders = orders;
          reviews.forEach(e => {
            e.imgs = e.img.split('|').map(i => i.startsWith('RV') ? `https://www.balaan.co.kr/shop/data/review_tmp/${i}` : `https://i.balaan.io/review/${i}`);
          });
          this.reviews = reviews;
          this.cartWish = cartWish;
        }

        this.$forceUpdate();
      });

      // 발란몰, SS 의 최신 상품정보를 가져온다.
      if (this.$R('EXT_GOODS_X')) {
        // item.links.forEach(link=>{
        //   if (link.mall === 'smartstore') {
        //     getJson('/ss/syncGood?mall_id='+link.mall_id).then(j=>{
        //       if (j) this.itemAttr.ss = j.data;
        //     });
        //   }
        // });
      }

      // es 와 비교를 진행한다.
      if (item._id && this.collection !== 'terminated') this.diffCfES().then();

      // shopify 가격 계산을 위한 설정정보를 가져온다.
      this.$api.getJson('/goods/shopify/pricing').then(j => {
        this.shopifyPricing = j.now;
      });

      this.modal.detail = true;
    },
    async stockPick() {
      this.busy.stockPick = true;
      let j = await this.$api.getFeed('/fetch/stockPick?goods_no=' + this.item.goods_no);
      this.busy.stockPick = false;
      if (j) {
        this.$modal.show({title: 'StockPick', type: 'json', item: j});
      }
    },
    async syncStock() {
      this.busy.syncStock = true;
      let j = await this.$api.getJson(`/goods/syncStock?goods_no=${this.item.goods_no}&renew=true`);
      this.busy.syncStock = false;
      if (j) {
        this.item.options = j.options;
        this.$modal.show({title: '싱크 결과입니다. 수량을 확인해주세요.', type: 'json', item: j});
      }
    },
    async getES() {
      this.busy.getES = true;
      let j = await this.$api.getJson(`/goods/confirmed/getES?goods_no=${this.item.goods_no}`);
      this.busy.getES = false;
      if (j) {
        this.$modal.show({title: '발란몰 ES 의 bl_gm 데이터입니다', type: 'json', item: j.data});
      }
    },
    async syncES() {
      this.busy.syncES = true;
      let j = await this.$api.getJson(`/goods/syncES?goods_no=${this.item.goods_no}`);
      this.busy.syncES = false;
      if (j) {
        this.$alertTop(`갱신 요청되었습니다`);
      } else {
        this.$alertTop(`갱신에 문제가 있습니다`, {variants: 'danger'});
      }
    },
    async getCfES() {
      this.busy.getCfES = true;
      let j = await this.$api.getJson(`/goods/confirmed/getES?_id=${this.item._id}`);
      this.busy.getCfES = false;
      if (j) {
        this.$modal.show({title: 'ES Solution 의 hub.confirmed 데이터입니다', type: 'json', item: j.data});
      }
    },
    async syncCfES() {
      this.busy.syncCfES = true;
      let j = await this.$api.postJson(`/goods/syncCfES`, {_ids: [this.item._id]});
      this.busy.syncCfES = false;
      if (j) {
        this.$alertTop(`갱신 요청되었습니다`);
      } else {
        this.$alertTop(`갱신에 문제가 있습니다`, {variants: 'danger'});
      }
    },
    async diffCfES() {
      const j = await this.$api.getJson(`/goods/confirmed/diffCfES?_id=${this.item._id}`);
      if (j && j.diff && Object.keys(j.diff).length) {
        this.item.diffCfES = j.diff;
      } else {
        delete this.item.diffCfES;
      }
    },
    async getMpES() {
      this.busy.getMpES = true;
      let j = await this.$api.getJson(`/goods/mapped/getES?_id=${this.item.mapped._id}`);
      this.busy.getMpES = false;
      if (j) {
        this.$modal.show({title: 'ES Solution 의 second.mapped 데이터입니다', type: 'json', item: j.data});
      }
    },
    async updatePriceTable() {
      this.busy.updatePriceTable = true;
      let j = await this.$api.postJson(`/goods/updatePriceTable`, {goods_nos:this.item.goods_no, attach:true});
      this.busy.updatePriceTable = false;
      if (j) {
        if (j.errCnt) {
          utils.alert('다음 에러가 발생했습니다 : ' + JSON.stringify(j.err));
        } else {
          utils.alert('갱신되었습니다.');
          this.item.price_table = j.price_tables[0].price_table;
        }
      }
    },
    async setField(field, isModalOk) {
      try {
        let t = utils.typeOf(this.item[field]), v;
        if (t === 'number') {
          v = prompt('변경할 값을 입력해주세요', this.item[field]);
        } else if (isModalOk) {
          v = t === 'string' || this.item[field] == null ? this.fieldModalValue : JSON.parse(this.fieldModalValue);
        } else {
          this.fieldModalName = field;
          this.fieldModalType = t;
          this.fieldModalValue = t === 'string' ? this.item[field] : JSON.stringify(this.item[field], null, 2);
          this.fieldModalImageWarn = false;
          if (field === 'img_urls' && this.item.img_urls.length !== this.item.original_img_urls.length) { // img_urls 일 경우 원본수가 다르다면 original_img_urls, images 도 나타낸다.
            this.fieldModalImageWarn = true;
            this.fieldModalValue = JSON.stringify({img_urls: this.item.img_urls, original_img_urls: this.item.original_img_urls, images: this.item.images}, null, 2);
          }
          this.modal.field = true;
          return;
        }
        if (v != null) {
          if (t === 'number') v = +v;
          let j;
          if (field === 'img_urls') { // img_urls 일 경우 해당 순서를 비교하여 original_img_urls, images 도 바꿔준다.
            if (v.img_urls) { // 원본과 수가 다른 경우 변경내역 그대로 전송
              j = await this.$api.postJson('/goods/setField', {item: {goods_no: this.item.goods_no, ...v}});
            } else {
              // 순서를 비교하여 짜맞춘다.
              let asis = this.item.img_urls;
              let postItem = {goods_no: this.item.goods_no};
              let imgIdxs = asis.map((e,i)=>({e, i, tobeIdx: v.indexOf(e)}));
              if (this.item.images) {
                postItem.images = imgIdxs.map(e=>this.item.images[e.tobeIdx]).filter(e=>e);
              }
              if (this.item.original_img_urls) {
                postItem.original_img_urls = imgIdxs.map(e=>this.item.original_img_urls[e.tobeIdx]).filter(e=>e);
              }
              postItem.img_urls = v;
              j = await this.$api.postJson('/goods/setField', {item: postItem});
            }
          } else {
            j = await this.$api.postJson('/goods/setField', {item: {goods_no: this.item.goods_no, [field]:v}});
            if (j.warnings.length) {
              let body = j.warnings.map(e => `${e.warning || ''}`).join('<br/>');
              this.$modal.show({title: '업로드 이슈 확인', html: `${body}<br/>`});
            }
          }
          if (j) {
            this.item[field] = v;
            this.$forceUpdate();
          }
        }
      } catch (e) {
        utils.alert(`에러가 발생했습니다 : ${e.message}`);
      }
    },
    optionDiff(options, diff, table) {
      if (!diff) return [];
      const optDiff = diff.filter(e => e.options).reverse();
      if (!optDiff.length) return [];

      let opts = utils.clone(options);
      const cStr = v => v == null ? '' : `생성됨: ${v}`;
      const dStr = v => v == null ? '' : `제거됨: ${v}`;
      const cudStr = v => v == null ? '' : v.created != null ? `생성됨: ${v.created}` : v.updated_from != null || v.updated_to != null ? `${v.updated_from} => ${v.updated_to}` : v.deleted != null ? `제거됨: ${v.deleted}` : v;
      const rows = optDiff.map(e => {
        // options 를 역산해야 한다.
        if (utils.typeOf(e.options) === 'object') {
          const optDiffArr = [];
          Object.entries(e.options).forEach(([idx, obj]) => {
            // idx: {stock: {updated_from, updated_to}}
            const optDiff = {
              Size: opts[idx].Size,
              table,
              dt: e._dt,
              at: e._at,
              name: e._name,
              ip: e._ip
            };
            Object.entries(obj).forEach(([k, df]) => {
              if (df.created != null) {
                optDiff[k] = cStr(df);
                delete opts[idx][k];
              } else if (df.updated_from != null || df.updated_to != null) {
                optDiff[k] = cudStr(df);
                opts[idx][k] = df.updated_from;
              } else if (df.deleted != null) {
                optDiff[k] = dStr(df);
                opts[idx][k] = df.deleted;
              } else if (k === 'created') {
                optDiff.Size = cStr(opts[idx].Size);
                delete opts[idx]; // splice 를 하면 index 가 변경
              } else if (k === 'deleted') {
                optDiff.Size = dStr(opts[idx].Size);
                opts[idx] = df;
              }
              optDiff[k] = df;
            });
            optDiffArr.push(optDiff);
          });
          opts = opts.filter(e=>e); // delete 후 length 조절에 필요
          return optDiffArr;
        }
        // diff 역산
        e.options.forEach(o => { // {created: {}}, {Size: 'a', stock: {updated_from: 1}}
          if (o.created != null) {
            opts = opts.filter(e=>e.Size !== o.created.Size);
          } else if (o.Size != null) {
            const curOpt = opts.filter(e=>e.Size === o.Size)[0];
            if (!curOpt) return console.error(o.Size, 'not found in', opts);
            Object.entries(o).filter(kv => kv[0] !== 'Size').forEach(([k, df]) => {
              if (df.created != null) {
                delete curOpt[k];
              } else if (df.updated_from != null || df.updated_to != null) {
                curOpt[k] = df.updated_from;
              } else if (df.deleted != null) {
                curOpt[k] = df.deleted;
              }
            });
          } else if (o.deleted != null) {
            opts.push(o.deleted);
          }
        });
        return e.options.map(opt => Object.assign(opt.created ? {
          Size: opt.created.Size,
          goods_price: cStr(opt.created.goods_price),
          goods_consumer: cStr(opt.created.goods_consumer),
          stock: cStr(opt.created.stock),
          shop_stock: cStr(opt.created.shop_stock),
          order_stock: cStr(opt.created.order_stock),
        } : opt.deleted ? {
          Size: opt.deleted.Size,
          goods_price: dStr(opt.deleted.goods_price),
          goods_consumer: dStr(opt.deleted.goods_consumer),
          stock: dStr(opt.deleted.stock),
          shop_stock: dStr(opt.deleted.shop_stock),
          order_stock: dStr(opt.deleted.order_stock),
        } : {
          Size: opt.Size,
          goods_price: cudStr(opt.goods_price),
          goods_consumer: cudStr(opt.goods_consumer),
          stock: cudStr(opt.stock),
          shop_stock: cudStr(opt.shop_stock),
          order_stock: cudStr(opt.order_stock),
        }, {
          table,
          dt: e._dt,
          at: e._at,
          name: e._name,
          ip: e._ip,
        }));
      }).reduce((a, b) => a.concat(b), []);

      return rows;
    },
    optionDiffXlsx() {
      let rows = this.optionDiff(this.item.options, this.item._diff, 'confirmed');
      if (this.item.mapped._diff && this.item.mapped._diff && this.item.mapped._diff.length) {
        rows = rows.concat(this.optionDiff(this.item.mapped.options, this.item.mapped._diff, 'mapped')).sort((a, b) => { return a.dt.localeCompare((b.dt)); }).reverse();
      }
      rows.forEach(e => e.goods_no = this.item.goods_no);
      down(rows, null, 'table,goods_no,Size,stock,goods_price,goods_consumer,shop_stock,order_stock,dt,at,name,ip'.split(','), `OptionsDiff_${utils.dt()}`, 'xlsx');
    },
    jsonModal(title, json) {
      this.$modal.show({title, type:'json', item:json});
    },
    showDiffModal(item){
      this.$modal.show({title: '수정이력 확인', type: 'diff', item: item, db: 'hub', collection: 'confirmed'});
    },
    async statData() {
      this.busy.statData = true;
      let j = await this.$api.postJson('/goods/statData', {goods_no: this.item.goods_no});
      this.busy.statData = false;
      if (j) {
        this.$modal.show({title: 'statData', type: 'json', item: j});
      }
    },
    async epData() {
      this.busy.epData = true;
      let j = await this.$api.postJson('/goods/epData', {goods_no: this.item.goods_no});
      this.busy.epData = false;
      if (j) {
        this.$modal.show({title: 'epData', type: 'json', item: j});
      }
    },
    async webStore() {
      const urls = Array.from(new Set(this.item.original_img_urls.concat(this.item.replaced_img_urls || []).concat((this.item.images || []).map(e => e.url))
        .filter(e => e)));
      this.busy.webStore = true;
      let j = await this.$api.postJson('/goods/webStore', {urls});
      this.busy.webStore = false;
      if (j) {
        this.$modal.show({title: 'webStore', type: 'json', item: {urls, webStore: j.ws}});
      }
    },
    copyText(e) {
      let res = this.$utils.copyToClipboard(e);
      if (res) this.$alertTop(`"${e}" 로 복사되었습니다`);
    },
    webp(src, {thumb} = {}) {
      if (!src.includes('i.balaan.io')) return src;
      const img_base = src.replace(/\.\w+$/, '');
      const webp = thumb ? img_base + `_${thumb}.webp` : img_base + '.webp';
      return webp;
    },
    async refreshRank() {
      let j = await this.$api.postJson('/master/refreshRank', {id: this.item.id});
      if (j) {
        this.$utils.alert('카탈로그 랭킹 재계산이 시작되었습니다. 순차적으로 처리됩니다.');
      }

      // const res = await fetch(this.CATALOG_HOST + '/catalog/calcCatalogRankRequest', {
      //   method: 'post',
      //   headers: {'Content-Type': 'application/json', 'Accept-Encoding': 'gzip, deflate'},
      //   body: JSON.stringify({gmIds: [this.item.gm_id]}),
      // });
      // let json = await res.json();
      //
      // if (res.ok && json.ok) {
      //   this.$utils.alert('카탈로그 랭킹 재계산이 시작되었습니다. 순차적으로 처리됩니다.');
      // } else {
      //   this.$utils.alert('카탈로그 랭킹 재계산 요청이 실패하였습니다.');
      // }
    },
    async shopifySendGoods() {
      if (!confirm('Shopify 상품은 기본적으로 하루에 한 번 전체등록 됩니다. 해당 상품을 개별등록 하시겠습니까?')) return;
      const j = await this.$api.postJson('/goods/shopify/checkGoods', {goodsNos: [this.item.goods_no]});
      if (j) {
        const k = await this.$api.postJson('/goods/shopify/upsertGoods', {goodsNos: [this.item.goods_no]});
        if (k) {
          this.$utils.alert('등록 대기열에 추가되었습니다.');
        }
      }
    },
    makeSeriesFromDiff() {
      const priceDiff = (this.item._diff || []).filter(e => e.price && e.price.updated_from).reverse();
      const data = [{x: new Date(this.$utils.kstD()), y: [this.item.price, this.item.price, this.item.price, this.item.price], _d: this.$utils.kstD()}];
      for (const d of priceDiff) {
        const item = {
          x: new Date(d._d),
          y: d.price.updated_from > d.price.updated_to ?
            [d.price.updated_from, d.price.updated_from, d.price.updated_to, d.price.updated_to] :
            [d.price.updated_from, d.price.updated_to, d.price.updated_from, d.price.updated_to],
          _d: d._d
        };
        if (item._d === data[0]._d) { // merge
          data[0].y[0] = item.y[0];
          data[0].y[1] = Math.max(data[0].y[1], item.y[1]);
          data[0].y[2] = Math.min(data[0].y[2], item.y[2]);
        } else {
          data.unshift(item);
        }
      }
      Vue.set(this.series[0], 'data', data);
    },
  }
}
</script>
